import * as React from "react";
import { siteMetadata } from "../../gatsby-config.js";
import {
	Badge,
	Box,
	Checkbox,
	ChakraProvider,
	Container,
	Heading,
	HStack,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Progress,
	Stack,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Text,
} from "@chakra-ui/react";
import moment from "moment";

import theme from "../../theme";

import workoutData from "./workout-wvs.json";

// Generate checkboxes
const renderCheckboxes = (lift) => {
	// Create an array to store set checkboxes
	const setCheckboxes = [];

	// Create a checkbox for each set
	for (let i = 0; i < lift.sets; i++) {
		setCheckboxes.push(<Checkbox key={i} size="lg" />);
	}

	return setCheckboxes;
};

const wvsPage = () => {
	// Setup today's date
	let today = new Date();

	// Get today's name
	let todayName = today.toLocaleString("en-us", {
		weekday: "long",
	});

	// Set up this week
	const currentWeek = moment().format("W");

	// Calculate week progress (percent of year)
	const weekProgress = Math.round((currentWeek / 52) * 100);

	// Get day's number of week: 0-6 = M-F
	let todayNumber = today.getDay() - 1;

	return (
		<>
			<ChakraProvider theme={theme}>
				<Container maxW="container.xs" py={8}>
					<title>{siteMetadata.title}</title>

					<Box textAlign="center">
						<Heading as="h1" mb={0}>
							{siteMetadata.title}
						</Heading>
						<Text mb={4}>It's {todayName}, legend.</Text>
					</Box>

					<Box mb={8}>
						<Badge variant="outline" mb={2}>
							Week {currentWeek}/52
						</Badge>
						<Progress value={weekProgress} />
					</Box>

					<Tabs defaultIndex={todayNumber} isFitted>
						<TabList>
							{workoutData.weekdays.map((weekday) => {
								// Return short day name for tab links (eg. "Mon")
								return (
									<Tab key={weekday.name}>{weekday.name.substring(0, 3)}</Tab>
								);
							})}
						</TabList>
						<TabPanels>
							{workoutData.weekdays.map((weekday) => {
								return (
									<TabPanel key={weekday.title} py={8} px={0}>
										<Heading as="h2" size="md" pb={6} mb={2}>
											{weekday.title}
										</Heading>
										<Box p="0" m="0">
											{weekday.lifts.map((lift) => {
												return (
													<Box
														key={`${weekday.title} - ${lift.name}`}
														pb={6}
														mb={2}
													>
														<HStack
															justify="space-between"
															align="start"
															mb={2}
														>
															<Box display="inline-block">
																<Text fontWeight="bold" mb={0}>
																	{lift.name}
																</Text>
																<Text fontSize="sm" mb={2}>
																	({lift.variation})
																</Text>

																<HStack
																	key={`${weekday.title}-${lift.name}-checkboxes`}
																	id="checkboxes"
																	spacing={5}
																>
																	{renderCheckboxes(lift)}
																</HStack>
															</Box>

															<Box
																maxW="120px"
																display="inline-block"
																textAlign="right"
															>
																<Stack align="end">
																	<HStack>
																		<NumberInput
																			size="xs"
																			step={2.5}
																			precision={1}
																			defaultValue={lift.weight}
																		>
																			<NumberInputField textAlign="right" />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>

																		<Badge minW="40px" textAlign="left">
																			Lbs
																		</Badge>
																	</HStack>

																	<HStack>
																		<NumberInput
																			size="xs"
																			defaultValue={lift.sets}
																		>
																			<NumberInputField textAlign="right" />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>

																		<Badge minW="40px" textAlign="left">
																			Sets
																		</Badge>
																	</HStack>

																	<HStack>
																		<NumberInput
																			size="xs"
																			defaultValue={lift.reps}
																		>
																			<NumberInputField textAlign="right" />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>

																		<Badge minW="40px" textAlign="left">
																			Reps
																		</Badge>
																	</HStack>
																</Stack>
															</Box>
														</HStack>
													</Box>
												);
											})}
										</Box>
									</TabPanel>
								);
							})}
						</TabPanels>
					</Tabs>
				</Container>
			</ChakraProvider>
		</>
	);
};

export default wvsPage;
